<template>
  <button type="button" :class="{ 'wy-selected': selected }">
    <!-- Absolute positioned -->
    <div class="is-selected" v-if="selected">
      <checkmark-icon />
    </div>

    <div class="title">{{ title }}</div>
    <div class="subtitle">{{ subtitle }}</div>
  </button>
</template>

<script>
import CheckmarkIcon from "./icons/CheckmarkIcon.vue";

export default {
  name: "BedroomSelectButton",
  components: { 
    CheckmarkIcon 
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 59px;
  border-radius: 50px;
  border: 3px solid var(--primaryColor);
  background: transparent;
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  max-width: 48%;

  &:hover {
    background-color: var(--primaryColorOp20);
  }

  &:focus-visible {
    outline: none;
  }
  &.wy-selected{
    border-color: var(--secondaryColor);
  }

  .is-selected {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .title {
    font-size: 19px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--primaryColor);
  }

  .subtitle {
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--primaryColorOp65);
  }
}
</style>
