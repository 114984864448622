<template>
  <div>
    <div class="content symple-font">
        <div class="dialog">
            <div >
                <img class="logo" src="./assets/images/wyse-logo.png" />
            </div>
            <div class="pardon">
                An automated leasing assistant powered by AI and machine learning.
            </div>
            <div class="link">
                <a href="mailto:info@dyverse.com" class="contact-link">
                    <button class="btn">
                        Schedule Demo</button>
                </a>
            </div>
            
        </div>
        <div class="copyright">
            <p>©2022 Dyverse Marketing Group, Inc.</p>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  created: () => {
  },
  components: {
  },
};
</script>

<style src="@/assets/css/main.css"></style>
<style src="@/assets/css/animations.css"></style>

<style lang="scss">
// .animate__delay-25s {
//   -webkit-animation-delay: .25s;
//   animation-delay: .25s;
// }

// .animate__delay-50s {
//   -webkit-animation-delay: .50s;
//   animation-delay: .50s;
// }

// $animationDuration: 0.5s;
// @import "vue2-animate/src/sass/vue2-animate.scss";

body {
    // background-color: #FA7921;
    background-image: linear-gradient(to right, #FA7921 , #FAA321);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100vw !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 100vh !important;
}

.contact-link {
    text-decoration: none;
    color: #FA7921;
}
.content {
    margin: 0 auto 0 auto;
    padding-top: 20vh;
    width: 40vw;
}
.dialog {
    background: #fff;
    padding: 50px 30px;
    border-radius: 20px;
}
.logo {
    max-width: 100px;
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.pardon {
    text-align: center;
    margin-top: 15px;
    line-height: 22px;
}
.link {
    text-align: center;
    margin-top: 10px;
}
.copyright {
    text-align: center;
    color: #ffffff;
    margin-top: 10px;
}
.btn {
    font-family: Lato;
    font-weight: bold;
    font-size: 16px;
    color: white;
    background-color: #FA7921;
    border-radius: 20px;
    border: none;
    text-decoration: none;
    padding: 11px 30px;
    transition: 0.3s;
    margin-top: 8px;

}
.btn:hover {
    background-color: #FAA321;

}
i {
    margin-right: 5px;
}

@media only screen and (max-width: 800px) {
    .content {
        width: 95vw;
    }
  }

  @media only screen and (max-width: 1100px) {
    .content {
        width: 70vw;
    }
  }


</style>
