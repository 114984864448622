<template>
  <button :type="type" :disabled="disabled" class="wyse-base-button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "WyseButton",
  props: {
    type: {
      type: String,
      default: "button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;
  text-align: center;
  padding: 11px 0 12px;
  border-radius: 100px;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  display: block;
  color: var(--primaryColor);
  border: 3px solid var(--primaryColor);
  margin-bottom: 0px;
  transition: background-color 0.3s ease-in-out;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: var(--primaryColorOp20);
  }

  &:focus-visible {
    outline: none;
  }
}
</style>
