<template>
  <layout title="Availability" logo="" class="check-availability">
    <template>
      <div class="availability-filter-container animate__animated animate__fadeIn animate__delay-50s">
        <div class="availability-filter-wrapper">
          <div class="wyse-select-container">
            <wyse-select
              defaultText="# Beds"
              :options="bedroomOptions"
              v-model="selectedBedrooms"
            />
            <wyse-select
              defaultText="Floor Plans"
              :options="roomTypeOptions"
              v-model="selectedRoomType"
            />
          </div>

          <wyse-range v-model="currentRentValue" :max="`${maxRent}`" />

          <p class="max-rent">Max Rent ${{ currentRentValue }}</p>
        </div>

        <div class="availability-cards-wrapper">
          <availability-card
            v-for="(information, index) in selectedList"
            :key="index"
            :information="information"
            :type="selectedRoomType"
          />
          <tips-text class="availibility-disclaimer">
            Prices may vary depending on your preferred lease term, lease signing
            date and other conditions. Square footage is approximate and may vary
            as well.
          </tips-text>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <page-footer
        footerText="Powered by"
        @click.native="handleFooterClick()"
      />
    </template>
  </layout>
</template>

<script>
import PageFooter from "../components/layout/PageFooter.vue";
import Layout from "../components/layout/Layout.vue";
import WyseRange from "../components/form/WyseRange.vue";
import WyseSelect from "../components/form/WyseSelect.vue";
import AvailabilityCard from "../components/AvailabilityCard.vue";
import TipsText from "../components/TipsText.vue";

function floorPlanAndApartmentUnitParser(f) {
  return {
    ...f,
    rent_max: parseInt(f.rent_max),
    rent_min: parseInt(f.rent_min),
    sq_ft_max: parseInt(f.sq_ft_max),
    sq_ft_min: parseInt(f.sq_ft_min),
  };
}

function checkAvailabilitySortFunction(a, b) {
  return a.rent_min - b.rent_min;
}

function checkAvailabilityFilterFunction(a) {
  return a.sq_ft_min > 0 || a.rent_min >= 100;
}

export default {
  name: "CheckAvailability",
  components: {
    AvailabilityCard,
    Layout,
    PageFooter,
    WyseRange,
    WyseSelect,
    TipsText,
  },
  data() {
    return {
      selectedBedrooms: "all",
      selectedRoomType: "apartment",
      bedroomOptions: [
        { text: "All Floor Plans", value: "all" },
        { text: "Studio", value: "0" },
        { text: "1 Bed", value: "1" },
        { text: "2 Bed", value: "2" },
      ],
      roomTypeOptions: [
        { text: "Apartments", value: "apartment" },
        { text: "Floor Plans", value: "floorPlan" },
      ],
      currentRentValue: "0",
    };
  },
  methods: {
    handleFooterClick() {
      this.$router.go(-1);
    },
  },
  computed: {
    availabilityData() {
      return this.$store.state.availability.data;
    },

    floorPlans() {
      const attributes = this.availabilityData?.attributes;
      if (attributes?.floor_plans) {
        return attributes.floor_plans
          .map(floorPlanAndApartmentUnitParser)
          .sort(checkAvailabilitySortFunction)
          .filter(checkAvailabilityFilterFunction);
      }
      return [];
    },

    apartments() {
      const attributes = this.availabilityData?.attributes;
      if (attributes?.units) {
        return attributes.units
          .map(floorPlanAndApartmentUnitParser)
          .sort(checkAvailabilitySortFunction)
          .filter(checkAvailabilityFilterFunction);
      }
      return [];
    },

    allList() {
      if (this.selectedRoomType === "floorPlan") return this.floorPlans;
      else if (this.selectedRoomType === "apartment") return this.apartments;
      return [];
    },

    selectedList() {
      let result;
      if (this.selectedBedrooms === "all") {
        result = this.allList;
      } else {
        result = this.allList.filter(
          (f) => f?.bedrooms === this.selectedBedrooms
        );
      }
      return result.filter(
        (f) => f.rent_min <= parseInt(this.currentRentValue)
      );
    },

    maxRent() {
      let max = 0;
      this.allList.map((f) => {
        if (f?.rent_max > max) {
          max = f.rent_max;
        }
      });
      return max > 100 ? `${max}` : `100`;
    },
  },
  watch: {
    allList(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentRentValue = this.maxRent;
      }
    },
  },
  mounted() {
    if (this.allList.length > 0) {
      let max = 0;
      this.allList.map((f) => {
        if (f?.rent_max > max) {
          max = f.rent_max;
        }
      });
      max = max > 100 ? `${max}` : `100`;
      this.currentRentValue = max;
    }
  },
};
</script>

<style lang="scss">
.availability-filter-container{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.availability-filter-wrapper{
  flex-shrink: 0;
}
.availability-cards-wrapper {
  flex: 1 1 0%;
  overflow-y: auto;
}


.check-availability {
  .header {
    margin-bottom: 17px;
    min-height: 35px !important;
  }
}

.wyse-select-container {
  display: flex;
  justify-content: space-between;
  & > div {
    width: 48%;
  }
}

.max-rent {
  text-align: center;
  margin-top: 6px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0em;
  margin-bottom: 15px;
}

.availibility-disclaimer {
  margin-right: 10px;
  margin-bottom: 10px;
}

.wyse-option {
  font-size: 17px;
  font-weight: 400 !important;
}

</style>
