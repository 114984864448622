<template>
  <div class="">

  </div>
</template>

<script>
export default {
  name: "Page",
  props: {
    maxWidth: Number,
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: auto;
  padding: 30px 20px 0;
}
</style>
