<template>
  <div :class="{ 'select-container': true, squared }">
    <div
      :class="{ 'select-button': true, active: open, 'has-error': error }"
      @click="toggleOptions()"
    >
      <p v-if="value">{{ selectedValue }}</p>
      <p v-else>
        Select
        <span class="required-star" v-if="required">*</span>
      </p>

      <caret-down-icon :class="{ 'select-caret': true, rotated: open }" />
    </div>
    <transition name="fade">
      <div v-if="open" class="options-container">
        <div
          v-for="option in options"
          :key="option.value"
          @click="handleInput(option.value)"
          class="wyse-option"
        >
          {{ option.text }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CaretDownIcon from "../icons/CaretDownIcon.vue";

export default {
  name: "WyseSelect",
  data: () => {
    return { open: false };
  },
  components: {
    CaretDownIcon,
  },
  props: {
    defaultText: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    squared: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleOptions() {
      this.open = !this.open;
    },

    handleInput(value) {
      this.toggleOptions();
      this.$emit("input", value);
    },
  },
  computed: {
    selectedValue() {
      const selectedOption = this.options.find((o) => o.value === this.value);
      return selectedOption?.text;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-container {
  position: relative;
}

.squared {
  .select-button {
    border-radius: 0;
  }
  .wyse-option {
    &:first-child,
    &:last-child {
      border-radius: 0;
    }
  }
}

.select-button {
  padding: 11px 17px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  cursor: pointer;
  border: 3px solid var(--primaryColorOp25);
  border-radius: 24px;

  &.has-error {
    border-color: #c31118;
  }

  .required-star {
    color: var(--secondaryColor);
  }

  .select-caret {
    transition: all 0.2s ease-in-out;
    width: 14px;
    &.rotated {
      transform: rotate(180deg);
    }
  }
}

.options-container {
  position: absolute;
  width: 100%;
  top: 105%;
  border-bottom-left-radius: var(--rounded_corners);
  border-bottom-right-radius: var(--rounded_corners);
  background-color: var(--input_background_color);
  z-index: 3;
  border: 2px solid var(--input_border_color);
  border-top: none;
  overflow: hidden;
  cursor: pointer;
}

.wyse-option {
  color: var(--primaryColor);
  padding: 11px 17px;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  box-sizing: border-box;
  background: var(--backgroundColor);
  border: 3px solid var(--primaryColorOp25);
  border-bottom: none;
  transition: all 0.3s ease-in-out;

  &:first-child {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }
  &:last-child {
    border-bottom: 3px solid var(--primaryColorOp25);
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
  }

  &:hover {
    background: var(--backgroundColorLighten10);
  }

  &:focus {
    border-color: var(--primaryColor);
  }
}
</style>
