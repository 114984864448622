<template>
  <layout
    title="Which day would you like to schedule a tour?"
    logo="/images/calendar-icon.svg"
  >
    <div class="buttons-container animate__animated animate__fadeIn animate__delay-50s">
      <date-button
        v-for="tourDate in tourDates"
        :key="tourDate.toString()"
        :date="tourDate"
        :selected="getDateStringFromDate(tourDate) == selectedTourDate"
        @click.native="handleButtonClick(tourDate)"
      />
    </div>

    <template v-slot:footer>
      <page-footer footerText="Powered by" @click.native="handleFooterClick()" />
    </template>
  </layout>
</template>

<script>
import { mapMutations } from "vuex";
import router from "@/router/index";
import Layout from "@/components/layout/Layout.vue";
import PageFooter from "../components/layout/PageFooter.vue";
import DateButton from "../components/DateButton.vue";
import { getDateFormData, getDateStringFromDate } from "../utils";

export default {
  name: "ScheduleTourPage2",
  components: {
    DateButton,
    Layout,
    PageFooter,
  },
  methods: {
    ...mapMutations(["setTourDate"]),
    getDateStringFromDate,
    handleButtonClick(date) {
      this.setTourDate(date);
      router.push("/schedule-3");
    },
    handleFooterClick() {
      router.go(-1);
    },
  },
  computed: {
    tourDates() {
      const tourTimes = this.$store.state.tourTimes.data;
      return tourTimes.map((t) => new Date(t.startDate));
    },
    selectedTourDate() {
      const tourDate = this.$store.state.formData.tourDate;
      if (tourDate) {
        const tourDateFormData = getDateFormData(tourDate);
        return tourDateFormData.split(" ")[1];
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
