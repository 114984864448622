<template>
  <div class="wyse-range">
    <input type="range" :value="value" @input="handleInput" :max="max" />
  </div>
</template>

<script>
export default {
  name: "WyseRange",

  props: {
    value: {
      type: String,
      required: true,
    },
    max: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleInput(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.wyse-range {
  margin-top: 15px;
  border: 3px solid rgba(255, 255, 255, 0.35);
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
}

input[type="range"] {
  width: 100%;
  margin: -4.5px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  /* background: #199743; */
  border: 0;
  width: 100%;
  height: 39px;
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  margin-top: 4.5px;
  width: 30px;
  height: 30px;
  background: var(--primaryColor);
  border: 2.9px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type="range"]::-moz-range-track {
  background: rgba(0, 0, 0, 0);
  border: 0;
  width: 100%;
  height: 39px;
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
  width: 30px;
  height: 30px;
  background: var(--primaryColor);
  border: 2.9px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 0 0;
  color: transparent;
  width: 100%;
  height: 39px;
  cursor: pointer;
}
input[type="range"]::-ms-fill-lower {
  /* background: #158139; */
  border: 0;
}
input[type="range"]::-ms-fill-upper {
  /* background: #199743; */
  border: 0;
}
input[type="range"]::-ms-thumb {
  width: 30px;
  height: 30px;
  background: var(--primaryColor);
  border: 2.9px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type="range"] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
</style>
