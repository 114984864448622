<template>
  <form @submit.prevent="handleFormSubmit()" class="animate__animated animate__fadeIn animate__delay-50s">
    <wyse-input-group
      type="text"
      :error="errors.firstName || errors.lastName"
      label="Name"
      placeholderOne="First"
      placeholderTwo="Last"
      :value-one="formData.firstName"
      :value-two="formData.lastName"
      @valueOne="formData.firstName = $event"
      @valueTwo="formData.lastName = $event"
      required
    />
    <wyse-input
      type="email"
      :error="errors.email"
      label="Email"
      v-model="formData.email"
      required
    />

    <wyse-base-button type="submit" :disabled="loading">
      <spinner v-if="loading" :size="20" :borderWidth="3" />
      <template v-else>Continue</template>
    </wyse-base-button>
  </form>
</template>

<script>
import WyseInput from "../components/form/WyseInput";
import Spinner from "./Spinner.vue";
import WyseBaseButton from "./WyseBaseButton.vue";
import WyseInputGroup from "../components/form/WyseInputGroup";

export default {
  name: "ApplyForm",
  components: {
    WyseBaseButton,
    WyseInput,
    WyseInputGroup,
    Spinner,
  },
  inject: ["formHandler"],
  data() {
    return {
      loading: false,
      formData: {
        firstName: "",
        lastName: "",
        email: "",
      },
      errors: {
        firstName: false,
        lastName: false,
        email: false,
      },
    };
  },
  methods: {
    handleFormSubmit() {
      Object.keys(this.formData).forEach((k) => {
        this.errors[k] = !this.formData[k];
      });
      let isValid = Object.values(this.errors).every((v) => !v);
      console.log(this.formData);
      console.log({ isValid });
      if (isValid) {
        this.loading = true;
        this.formHandler
          .applyNow(
            this.formData.firstName,
            this.formData.lastName,
            this.formData.email
          )
          .then((d) => {
            console.log({ d });
            this.$router.push("/schedule-success");
          })
          .catch(() => {
            this.$router.push("/submission-error");
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wyse-base-button {
  margin-top: 25px;
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
}
</style>
