<template>
  <layout
    title="Inquire"
    :logo="logo"
    class="contact-us-page"
  >
    <inquire-form />

    <template v-slot:footer>
      <page-footer footerText="Powered by" @click.native="handleFooterClick()" />
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/layout/Layout.vue";
import PageFooter from "@/components/layout/PageFooter.vue";
import router from "../router/index";
import InquireForm from "@/components/InquireForm.vue";

export default {
  name: "InquireAvailability",
  components: {
    Layout,
    PageFooter,
    InquireForm,
  },
  methods: {
    handleFooterClick() {
      router.back();
    },
  },
  computed: {
    pageTitle() {
      let title = "Thank you for your interest";
      if (this.settings.property_name) {
        title += " in " + this.settings.property_name;
      }
      return title;
    },
    logo() {
      return this.$store.state.settings.interface?.logo ? this.$store.state.settings.interface.logo : '/images/bot-icon.svg';
    },
  },
  inject: ["settings"],
};
</script>
