<template>
  <layout
    :title="pageTitle"
    logo="/images/bot-icon.svg"
    class="schedule-page-4"
  >
    <schedule-form />

    <template v-slot:footer>
      <page-footer footerText="Powered by" @click.native="handleFooterClick()" />
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/layout/Layout.vue";
import PageFooter from "@/components/layout/PageFooter.vue";
import router from "../router/index";
import ScheduleForm from "@/components/ScheduleForm.vue";
import { getDateStringFromDate } from "../utils";

export default {
  name: "ScheduleTourPage4",
  components: {
    Layout,
    PageFooter,
    ScheduleForm,
  },
  methods: {
    handleFooterClick() {
      router.go(-1);
    },
  },
  computed: {
    pageTitle() {
      let title = "Please provide the following details";
      const { tourDate, tourTime } = this.$store.state.formData;

      if (tourDate && tourTime) {
        const formattedTourDate = getDateStringFromDate(tourDate);
        const formattedTourTime = tourTime.replace(/.{2}$/, " $&");

        title += ` to confirm your tour on ${formattedTourDate} at ${formattedTourTime}`;
      }
      return title;
    },
  },
  inject: ["settings"],
};
</script>