<template>
  <div
    class="spinner"
    :style="{
      width: size + 'px',
      height: size + 'px',
      borderWidth: borderWidth + 'px',
    }"
  ></div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    size: {
      type: Number,
      default: 85,
    },
    borderWidth: {
      type: Number,
      default: 7,
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  border: 7px solid transparent;
  border-top: 7px solid var(--primaryColor);
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
  margin: 3px auto;
}
</style>
