<template>
  <div class="availability-card" @click="handleClick()">
    <div class="top-container">
      <div>
        <!-- <camera-icon /> -->
        {{ title }}
      </div>
      <div>
        INQUIRE
        <right-arrow-icon />
      </div>
    </div>
    <p class="subtitle">{{ subtitle }}</p>
  </div>
</template>

<script>
import RightArrowIcon from "./icons/RightArrowIcon.vue";
// import CameraIcon from "./icons/CameraIcon.vue";

export default {
  name: "AvailabilityCard",

  components: { RightArrowIcon },

  props: {
    information: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleClick() {
      this.$store.commit("setAvailabilityBedroomCount", this.bedrooms);
      this.$router.push("/inquire-availability");
    },
  },

  computed: {
    title() {
      if (this.type === "floorPlan") return this.information?.floor_plan_id;
      else if (this.type === "apartment") return this.information?.unit_id;
      return "";
    },

    subtitle() {
      const { bedrooms, bathrooms, sq_ft_max, rent_min } = this.information;
      const bedText = bedrooms > 0 ? `${bedrooms} Bed` : `Studio`;
      return `${bedText}/${bathrooms} Bath | ${sq_ft_max} sq. ft. | From $${rent_min.toLocaleString()}`;
    },

    bedrooms() {
      return this.information.bedrooms;
    },

    buttonText() {
      if (this.type === "floorPlan") return "View Floor Plan";
      else if (this.type === "apartment") return "View Apartment";
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.availability-card {
  border: 2px solid rgba(255, 255, 255, 0.35);
  padding: 10px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  letter-spacing: 0em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--backgroundColorLighten10);
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  }

  .subtitle {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
  }

  .top-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    & > div {
      display: flex;
      align-items: center;

      &:first-child {
        svg {
          margin-right: 9px;
        }
        font-size: 16px;
        font-weight: 600;
      }

      &:last-child {
        svg {
          margin-left: 9px;
        }
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-align: right;
      }
    }
  }
}
</style>
