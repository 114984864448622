<template>
  <div :class="{ 'input-wrapper': true, error: error, active: active }">
    <p class="input-leading-text">
      <span>
        {{ label }}<span v-if="required" class="required-star">*</span>
      </span>
    </p>
    <input
      v-if="!options"
      :type="type"
      :value="value"
      @input="handleInput"
      @focus="active = true"
      @blur="active = false"
    />
    <wyse-select
      v-else
      class="wyse-input-select"
      defaultText="Select"
      v-model="selectValue"
      :options="options"
    />
  </div>
</template>

<script>
import WyseSelect from "./WyseSelect.vue";
export default {
  components: { WyseSelect },
  name: "WyseInput",
  data: () => {
    return { active: false, selectValue: null };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: false,
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.value);
    },
  },
  watch: {
    selectValue(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  width: 100%;
  position: relative;
  /*
  border-bottom: 2px solid var(--primaryColorOp35);
  margin-top: 24px;
  */
  padding-bottom: 13px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:first-child {
    margin-top: 0;
  }

/*
  &.active {
    //border-bottom: 2px solid var(--primaryColor);

    .input-leading-text {
      color: var(--primaryColor);
    }
  }
*/

  &.error {
    /*
    border-bottom: 2px solid #c31118;

    .input-leading-text {
      color: #bb0007;
    }
    */

    input{
      border-color: var(--secondaryColor);
    }
  }

  .input-leading-text {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100px;
    height: 100%;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: var(--primaryColor);
    padding-left: 3px;
    transition: all 0.3s ease-in-out;

    .required-star {
      color: var(--secondaryColor);
    }
  }

  input {
    width: 100%;
    outline: none;
    margin-left: 90px;
    padding: 7px 10px;
    border: 2px solid var(--primaryColorOp35);
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    box-sizing: border-box;
    background-color: transparent;
    color: var(--primaryColor);
    transition: all 0.3s ease-in-out;
    font-family: "Lato", sans-serif;
    border-radius: 5px;
    &:focus {
      border-color: var(--primaryColor);
    }
  }

  .wyse-input-select {
    width: 100%;
    margin-left: 90px;
    border: 2px solid var(--primaryColorOp35);
    border-radius: 5px;
    color: var(--primaryColorOp35);
  }
}
</style>
<style lang="scss">
.wyse-input-select {
  .select-button {
    border: none;
    padding-left: 10px;
    p {
      font-weight: 400;
      color: var(--primaryColor);
    }
  }
  .options-container {
    .wyse-option {
      font-weight: 400;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: var(--backgroundColorLighten5);
      }

      &:first-child,
      &:last-child {
        border-radius: 0;
      }
    }
  }
}
</style>
