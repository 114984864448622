<template>
    <span class="go-forward" @click="goForward">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0512 21.7277C12.1228 21.7393 12.1952 21.7446 12.2677 21.7437L24.6069 21.7437L24.3378 21.8688C24.0748 21.9933 23.8356 22.1627 23.6308 22.3694L20.1705 25.8296C19.7148 26.2647 19.6383 26.9645 19.9891 27.4878C20.3974 28.0454 21.1805 28.1665 21.7381 27.7582C21.7832 27.7252 21.826 27.6892 21.8662 27.6505L28.1234 21.3933C28.6124 20.9048 28.6129 20.1125 28.1244 19.6235C28.1241 19.6232 28.1238 19.6228 28.1234 19.6225L21.8662 13.3653C21.3769 12.8773 20.5845 12.8784 20.0964 13.3678C20.058 13.4063 20.0222 13.4473 19.9891 13.4904C19.6383 14.0137 19.7148 14.7136 20.1705 15.1486L23.6245 18.6151C23.8081 18.7989 24.0192 18.953 24.2502 19.0719L24.6257 19.2408H12.3365C11.6972 19.2171 11.1364 19.6636 11.0163 20.292C10.9056 20.9742 11.369 21.617 12.0512 21.7277Z" fill="currentColor"/>
            <circle cx="20" cy="20" r="19" stroke="currentColor" stroke-opacity="0.25" stroke-width="2"/>
        </svg>
    </span>
</template>

<script>
export default ({
    methods: {
        goForward(){
            return this.$router.go(1);
        }
    }
})
</script>
<style scoped>
    .go-forward{
        position: absolute;
        top: 0;
        right: 10px;
        opacity: 0.5;
        transition: all .5s;
        cursor: pointer;
        z-index: 9999;
    }
    .contact-us-page .go-forward,
    .wy-apply .go-forward,
    .wy-home .go-forward{
        display: none;
    }
    .go-forward:hover{
        opacity: 1;
    }
    svg {
        color: var(--primaryColor);
    }

</style>