<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Container",
};
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  overflow-y: auto;
}
</style>
