<template>
  <layout :title="title" :logo="logo" class="wy-home">
    <div class="nav-container">
      <router-link class="home-navigation-button animate__animated animate__fadeInUp animate__delay-25s" to="/schedule-1">
        Schedule a Tour
      </router-link>

      <router-link class="home-navigation-button animate__animated animate__fadeInUp animate__delay-25s" to="/check-availability">
        Check Availability
      </router-link>

      <router-link class="home-navigation-button animate__animated animate__fadeInUp animate__delay-25s" to="/contact-us">
        Ask a Question
      </router-link>

      <router-link class="home-navigation-button animate__animated animate__fadeInUp animate__delay-25s" to="/apply">
        Apply Now
      </router-link>
    </div>

    <a :href="'tel:' + phone" class="call-button animate__animated animate__fadeInUp animate__delay-50s">
      <div class="info">
        <phone-icon />
        <h3>Talk to a Leasing Agent</h3>
      </div>
    </a>

    <template v-slot:footer>
      <page-footer
        footerText="Powered by"
        @click.native="closeIframe()"
      />
    </template>
  </layout>
</template>

<script>
import PhoneIcon from "../components/icons/PhoneIcon.vue";
import PageFooter from "../components/layout/PageFooter.vue";
import Layout from "../components/layout/Layout.vue";
import { closeIframe } from "../utils";

export default {
  name: "Home",
  components: {
    Layout,
    PhoneIcon,
    PageFooter,
  },
  methods: { closeIframe },
  computed: {
    phone() {
      return this.$store.state.settings?.phone;
    },
    logo() {
      return this.$store.state.settings.interface?.logo ? this.$store.state.settings.interface.logo : '/images/bot-icon.svg';
    },
    title() {
      if (this.$store.state.settings.interface?.logo) {
        return 'How can we help?'
      } else {
        return 'How can I help?'
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.nav-container {
  padding: 0 10px;

  .home-navigation-button {
    width: 100%;
    text-align: center;
    padding: 11px 0 12px;
    border-radius: 100px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    display: block;
    color: var(--primaryColor);
    border: 3px solid var(--primaryColor);
    margin-bottom: 15px;
    transition: background-color 0.3s ease-in-out;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: var(--primaryColorOp20);
    }
  }
}

.call-button {
  text-decoration: none;
  margin-top: 25px;


  &:hover #phone-icon {
    animation: tada 0.7s infinite;
  }

  .info {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 19px;
      line-height: 20px;
      color: var(--primaryColor);
      font-weight: 400;
      margin-left: 9px;
    }
  }
}
</style>
