<template>
  <layout
    title="Your tour has been scheduled on:"
    logo="/images/check-mark-icon.svg"
    class="schedule-success"
  >
    <div class="tour-date-and-time">
      <h1>{{ tourDateAndTime }}</h1>
    </div>

    <p class="info-text">
      A leasing representative will contact you within 24 hours to confirm. If
      you have any questions, or need to cancel or change your scheduled time,
      please <router-link to="/contact-us">contact us</router-link>.
    </p>

    <template v-slot:footer>
      <page-footer footerText="Powered by" @click.native="closeIframe()" />
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/layout/Layout.vue";
import PageFooter from "@/components/layout/PageFooter.vue";
// import router from "../router/index";
import { closeIframe, getDateStringFromDate } from "../utils";

export default {
  name: "ScheduleTourPage4",
  components: {
    Layout,
    PageFooter,
  },
  methods: {
    closeIframe,
  },
  computed: {
    tourDateAndTime() {
      const { tourDate, tourTime } = this.$store.state.formData;

      if (tourDate && tourTime) {
        const formattedTourDate = getDateStringFromDate(tourDate);
        const formattedTourTime = tourTime.replace(/.{2}$/, " $&");
        return `${formattedTourDate} at ${formattedTourTime}`;
      }
      return null;
    },
  },
  inject: ["settings"],
};
</script>

<style lang="scss">
.schedule-success {
  .tour-date-and-time {
    border-top: 2px solid var(--primaryColorOp20);
    border-bottom: 2px solid var(--primaryColorOp20);
    padding: 17px 0 19px;

    h1 {
      font-size: 27px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  .info-text {
    margin-top: 28px;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;

    a {
      color: var(--primaryColor);
      text-decoration: underline;
    }
  }
}
</style>
