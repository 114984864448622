export function getQueryParamValue(key) {
  var str = window.location.search;
  var objURL = {};

  str.replace(
      new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
      function( $0, $1, $2, $3 ){
          objURL[ $1 ] = $3;
      }
  );
  return objURL[key];
}

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function getDayNameFromDate(date) {
  const day = date.getDay();
  return weekdays[day];
}

export function getDateStringFromDate(date) {
  const [monthDay, month, year] = [
    date.getDate(),
    date.getMonth(),
    date.getFullYear(),
  ];
  const truncatedYear = year % 100;
  return `${month + 1}/${monthDay}/${truncatedYear}`;
}

export function getDateFormData(date) {
  const day = getDayNameFromDate(date);
  const dateString = getDateStringFromDate(date);
  return `${day} ${dateString}`;
}

export function getSettings() {
  const defaultSettings = {
    interface: {
      primaryColor: "#FFFFFF",
      secondaryColor: "#FAA321",
      backgroundColor: "#1098CA",
      iconColor: "#0094FF",
    },
    days: {
      saturday: false,
      sunday: false,
      exclude: "2019-12-25",
    },
    hours: {
      monday_open: "8:00",
      monday_close: "17:00",
      tuesday_open: "8:00",
      tuesday_close: "17:00",
      wednesday_open: "8:00",
      wednesday_close: "17:00",
      thursday_open: "9:00",
      thursday_close: "16:00",
      friday_open: "9:00",
      friday_close: "14:00",
      tours_per_hour: "2",
    },
    kyzen_api_id: "b72dbc",
    dyverse_api_id: "c2057a",
    property_name: "1600 Pennsylvania Ave SE",
    address: "618 E South St Ste 620, Orlando, FL 32801",
    phone: "+12025177640",
    visit_website: "http://dyverse.com",
    apply_link: "http://dyverse.com",
    checkAvailabilityButtons: {
      studio: false,
      oneBed: true,
      twoBeds: true,
      threeBeds: true,
    },
  };

  const paramsQueryParam = getQueryParamValue("params") || "{}";

  if (paramsQueryParam) {
    const parsedParamSettings = JSON.parse(
      decodeURIComponent(paramsQueryParam)
    );

    Object.assign(defaultSettings, parsedParamSettings);
  }
  return defaultSettings;
}

export function closeIframe() {
  if (window.parent) {
    window.parent.postMessage("close", "*");
  }
}
