<template>
  <form @submit.prevent="handleFormSubmit()" class="animate__animated animate__fadeIn animate__delay-50s">
    <wyse-input-group
      type="text"
      :error="errors.firstName || errors.lastName"
      label="Name"
      placeholderOne="First"
      placeholderTwo="Last"
      :value-one="formData.firstName"
      :value-two="formData.lastName"
      @valueOne="formData.firstName = $event"
      @valueTwo="formData.lastName = $event"
      required
    />
    <wyse-input
      type="email"
      :error="errors.email"
      label="Email"
      v-model="formData.email"
      required
    />
    <wyse-input
      type="tel"
      :error="errors.phone"
      label="Phone"
      v-model="formData.phone"
      required
    />

    <wyse-input
      type="text"
      :error="errors.desiredMoveInDate"
      label="Desired Move-in"
      v-model="formData.desiredMoveInDate"
      :options="[
        { value: 'Immediately', text: 'Immediately' },
        { value: '1 Month', text: '1 Month' },
        { value: '2 Months', text: '2 Months' },
        { value: '3 Months', text: '3 Months' },
        { value: 'More Than 3 Months', text: 'More Than 3 Months' },
      ]"
    />

    <tips-text v-if="!showMessage">Would you like to <a @click="showMessage = true">add a message</a> with your inquiry?</tips-text>
    <transition name="fade">
      <wyse-textarea
        v-if="showMessage"
        label="Message"
        :error="errors.message"
        v-model="formData.message"
      />
    </transition>

    <wyse-base-button type="submit" :disabled="loading" clas>
      <spinner v-if="loading" :size="20" :borderWidth="3" />
      <template v-else>Contact</template>
    </wyse-base-button>
  </form>
</template>

<script>
import WyseInput from "../components/form/WyseInput";
import WyseTextarea from "../components/form/WyseTextarea";
import Spinner from "./Spinner.vue";
import TipsText from "./TipsText.vue";
import WyseBaseButton from "./WyseBaseButton.vue";
import WyseInputGroup from "../components/form/WyseInputGroup";

export default {
  name: "InquireForm",
  components: {
    WyseBaseButton,
    WyseInput,
    WyseInputGroup,
    // WyseLabel,
    // WyseSelect,
    WyseTextarea,
    Spinner,
    TipsText,
  },
  inject: ["formHandler"],
  data() {
    return {
      loading: false,
      showMessage: false,
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
        desiredMoveInDate: "",
      },
      errors: {
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        message: false,
      },
    };
  },
  methods: {
    handleFormSubmit() {
      Object.keys(this.formData).forEach((k) => {
        if (k !== "message") this.errors[k] = !this.formData[k];
      });
      let isValid = Object.values(this.errors).every((v) => !v);
      console.log(this.formData);
      console.log({ isValid });
      if (isValid) {
        this.loading = true;
        this.formHandler
          .scheduleTour(
            this.formData.firstName,
            this.formData.lastName,
            this.formData.email,
            this.formData.phone,
            this.formData.message,
            this.formData.desiredMoveInDate,
            "",
            this.$store.state.formData.availabilityBedroomCount
          )
          .then((d) => {
            // TODO: Navigate to success page
            console.log(d);
            this.$router.push("/schedule-success");
          })
          .catch(() => {
            // TODO: Navigate to error page
            this.$router.push("/submission-error");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wyse-select-container {
  margin-top: 24px;
}

.wyse-base-button {
  margin-top: 25px;
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px !important;

}
</style>
