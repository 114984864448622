<template>
    <span class="go-back" @click="goBack">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.4393 19.2723C28.3677 19.2607 28.2953 19.2554 28.2228 19.2563H15.8836L16.1526 19.1312C16.4156 19.0067 16.6549 18.8373 16.8597 18.6306L20.3199 15.1704C20.7757 14.7353 20.8522 14.0355 20.5014 13.5122C20.0931 12.9546 19.31 12.8335 18.7523 13.2418C18.7073 13.2748 18.6645 13.3108 18.6242 13.3495L12.367 19.6067C11.878 20.0952 11.8776 20.8875 12.3661 21.3765C12.3664 21.3768 12.3667 21.3772 12.367 21.3775L18.6242 27.6347C19.1136 28.1227 19.906 28.1216 20.394 27.6322C20.4324 27.5937 20.4683 27.5527 20.5014 27.5096C20.8522 26.9863 20.7757 26.2864 20.3199 25.8514L16.866 22.3849C16.6823 22.2011 16.4712 22.047 16.2402 21.9281L15.8648 21.7592H28.1539C28.7932 21.7829 29.3541 21.3364 29.4742 20.708C29.5848 20.0258 29.1215 19.383 28.4393 19.2723Z" fill="currentColor"/>
            <circle cx="20" cy="20" r="19" stroke="currentColor" stroke-opacity="0.25" stroke-width="2"/>
        </svg>
    </span>
</template>
<script>
export default ({
    methods: {
        goBack(){
            return this.$router.go(-1);
        }
    }
})
</script>
<style scoped>
    .go-back{
        position: absolute;
        top: 0;
        left: 10px;
        opacity: 0.5;
        transition: all .5s;
        cursor: pointer;
        z-index: 9999;
    }
    .go-back:hover{
        opacity: 1;
    }
    .wy-home .go-back{
        display: none;
    }
    svg {
        color: var(--primaryColor);
    }
</style>
