<template>
  <layout
    title="How many bedrooms do you need?"
    :logo="logo"
  >
    <div class="buttons-container animate__animated animate__fadeIn animate__delay-50s" v-if="!bedrooms.loading">
      <bedroom-select-button
        v-for="bedroom in bedrooms.data"
        :key="bedroom.label"
        :bedroomInfo="bedroom"
        :selected="bedroom.formValue === selectedBedroom"
        @click.native="handleButtonClick(bedroom.formValue)"
      />
      <wyse-base-button
        class="i-dont-know-button" 
        @click.native="handleButtonClick('I don\'t know')"
      >
        <checkmark-icon
          class="check-icon"
          v-if="'I don\'t know' === selectedBedroom"
        />
        I don't know yet
      </wyse-base-button>
    </div>

    <tips-text class="animate__animated animate__fadeIn animate__delay-50s" style="margin-top: 15px;">
      Prices may change depending on your preferred lease term, lease signing
      date and other conditions. Square footage is approximate and may also
      vary.
    </tips-text>

    <template v-slot:footer>
      <page-footer footerText="Powered by" @click.native="handleFooterClick()" />
    </template>
  </layout>
</template>

<script>
import { mapMutations } from "vuex";
import router from "@/router";
import Layout from "@/components/layout/Layout.vue";
import TipsText from "@/components/TipsText.vue";
import BedroomSelectButton from "../components/BedroomSelectButton.vue";
import WyseBaseButton from "../components/WyseBaseButton.vue";
import CheckmarkIcon from "../components/icons/CheckmarkIcon.vue";
import PageFooter from "../components/layout/PageFooter.vue";

export default {
  name: "ScheduleTourPage1",
  components: {
    BedroomSelectButton,
    Layout,
    TipsText,
    CheckmarkIcon,
    WyseBaseButton,
    PageFooter,
  },
  methods: {
    ...mapMutations(["setDesiredBedroom"]),
    handleButtonClick(bedroomValue) {
      this.setDesiredBedroom(bedroomValue);
      router.push("/schedule-2");
    },
    handleFooterClick() {
      router.go(-1);
    },
  },
  computed: {
    bedrooms() {
      return this.$store.state.bedrooms;
    },
    selectedBedroom() {
      return this.$store.state.formData.desiredBedroom;
    },
    logo() {
      return this.$store.state.settings.interface?.logo ? this.$store.state.settings.interface.logo : '/images/bot-icon.svg';
    },
  },
};
</script>

<style lang="scss" scoped>
button.i-dont-know-button {
  height: 59px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .check-icon {
    position: absolute;
    left: 15px;
    top: 10px;
  }
}
</style>
