import Vue from "vue";
import VueRouter from "vue-router";

import ApplyNow from "../views/ApplyNow.vue";
import ContactUs from "../views/ContactUs.vue";
import CheckAvailability from "../views/CheckAvailability.vue";
import Home from "../views/Home.vue";
import InquireAvailability from "../views/InquireAvailability.vue";
import ScheduleTourPage1 from "../views/ScheduleTourPage1.vue";
import ScheduleTourPage2 from "../views/ScheduleTourPage2.vue";
import ScheduleTourPage3 from "../views/ScheduleTourPage3.vue";
import ScheduleTourPage4 from "../views/ScheduleTourPage4.vue";
import ScheduleSuccess from "../views/ScheduleSuccess.vue";
import SubmissionError from "../views/SubmissionError.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/apply",
    name: "ApplyNow",
    component: ApplyNow,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/check-availability",
    name: "CheckAvailability",
    component: CheckAvailability,
  },
  {
    path: "/schedule-1",
    name: "ScheduleTourPage1",
    component: ScheduleTourPage1,
  },
  {
    path: "/schedule-2",
    name: "ScheduleTourPage2",
    component: ScheduleTourPage2,
  },
  {
    path: "/schedule-3",
    name: "ScheduleTourPage3",
    component: ScheduleTourPage3,
  },
  {
    path: "/schedule-4",
    name: "ScheduleTourPage4",
    component: ScheduleTourPage4,
  },
  {
    path: "/schedule-success",
    name: "ScheduleSuccessPage",
    component: ScheduleSuccess,
  },
  {
    path: "/submission-error",
    name: "SubmissionError",
    component: SubmissionError,
  },
  {
    path: "/inquire-availability",
    name: "InquireAvailability",
    component: InquireAvailability,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
