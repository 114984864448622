<template>
  <p><slot></slot></p>
</template>

<script>
export default {
  name: "TipsText",
};
</script>

<style lang="scss" scoped>
p {
  font-family: Lato;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 5px;
  color: var(--primaryColorOp65);

  a {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: var(--primaryColor);
    }
  }
}
</style>
