<template>
  <layout :title="title" logo="/images/clock-icon.svg">
    <div class="buttons-container animate__animated animate__fadeIn animate__delay-50s">
      <column-button
        v-for="slot in tourSlots"
        :key="slot.title + slot.subtitle"
        :title="slot.title"
        :subtitle="slot.subtitle"
        :selected="selectedTourSlot === `${slot.title}${slot.subtitle}`"
        @click.native="handleButtonClick(slot)"
      />
    </div>

    <template v-slot:footer>
      <page-footer footerText="Powered by" @click.native="handleFooterClick()" />
    </template>
  </layout>
</template>

<script>
import { mapMutations } from "vuex";
import router from "@/router/index";
import Layout from "@/components/layout/Layout.vue";
import PageFooter from "../components/layout/PageFooter.vue";
import ColumnButton from "../components/ColumnButton.vue";
import { getDateFormData } from "../utils";

export default {
  name: "ScheduleTourPage3",
  components: {
    ColumnButton,
    Layout,
    PageFooter,
  },
  methods: {
    ...mapMutations(["setTourTime"]),
    handleButtonClick(slot) {
      this.setTourTime(`${slot.title}${slot.subtitle}`);
      router.push("/schedule-4");
    },
    handleFooterClick() {
      router.go(-1);
    },
  },
  computed: {
    title() {
      const tourDate = this.$store.state.formData.tourDate;
      const tourDateString = tourDate ? getDateFormData(tourDate) : "";
      return `What time on ${tourDateString} would you like to tour?`;
    },
    tourSlots() {
      const selectedTourDate = this.$store.state.formData.tourDate;
      const tourDate = this.$store.state.tourTimes.data.find(
        (t) => new Date(t.startDate).getTime() === selectedTourDate.getTime()
      );
      const slots = tourDate?.slots || [];
      return slots.map((s) => {
        const { startTime } = s;
        const [timeSlotString, amPm] = startTime.split(" ");
        const result = {
          subtitle: amPm,
        };
        // Remove seconds from timeSlotString
        result.title = timeSlotString
          .split(":")
          .slice(0, 2)
          .join(":");
        return result;
      });
    },
    selectedTourSlot() {
      return this.$store.state.formData.tourTime;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
