<template>
  <div class="textarea-wrapper">
    <label for="textarea-id">Message</label>
    <textarea id="textarea-id" :value="value" @input="handleInput" />
  </div>
</template>

<script>
export default {
  name: "WyseTextarea",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea-wrapper {
  margin-top: 10px;

  label {
    display: block;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 8px;
  }

  textarea {
    display: block;
    outline: none;
    width: 100%;
    background: transparent;
    height: 100px;
    padding: 7px 10px;
    color: var(--primaryColor);
    border: 2px solid var(--primaryColorOp35);
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 10px;
    box-sizing: border-box;
    font-family: Lato;
    font-size: 15px;
    line-height: 23px;

    &:focus {
      border-color: var(--primaryColor);
    }
  }
}
</style>
