<template>
  <layout
    title="Oops! An error occurred."
    logo="/images/cross-icon.svg"
    class="submission-error"
  >
    <p class="info-text">
      Something went wrong and your request did not go through. Please try
      contacting us by <a :href="'tel:' + phone">phone</a> with your request.
    </p>

    <template v-slot:footer>
      <page-footer
        footerText="Powered by"
        @click.native="closeIframe()"
      />
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/layout/Layout.vue";
import PageFooter from "@/components/layout/PageFooter.vue";
import { closeIframe, getDateStringFromDate } from "../utils";

export default {
  name: "SubmissionError",
  components: {
    Layout,
    PageFooter,
  },
  methods: {
    closeIframe,
  },
  computed: {
    tourDateAndTime() {
      const { tourDate, tourTime } = this.$store.state.formData;

      if (tourDate && tourTime) {
        const formattedTourDate = getDateStringFromDate(tourDate);
        const formattedTourTime = tourTime.replace(/.{2}$/, " $&");
        return `${formattedTourDate} at ${formattedTourTime}`;
      }
      return null;
    },

    phone() {
      return this.$store.state.settings?.phone;
    },
  },
  inject: ["settings"],
};
</script>

<style lang="scss">
.submission-error {
  .info-text {
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;

    a {
      color: var(--primaryColor);
      text-decoration: underline;
    }
  }
}
</style>
