<template>
  <page>
    <page-header :title="title" :logo="logo" />
    <container>
      <slot></slot>
    </container>
    <slot name="footer"> </slot>
  </page>
</template>

<script>
import Container from "./Container.vue";
import Page from "./Page.vue";
import PageHeader from "../PageHeader.vue";

export default {
  name: "Layout",
  components: {
    Page,
    PageHeader,
    Container,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      required: false,
    },
  },
};
</script>
