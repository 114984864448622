import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const initialState = {
  bedrooms: {
    data: [],
    loading: true,
  },
  tourTimes: {
    data: [],
    loading: true,
  },
  availability: {
    data: null,
    loading: true,
  },
  formData: {
    desiredBedroom: null,
    tourDate: null,
    tourTime: null,
    availabilityBedroomCount: null,
  },
  settings: {},
};

export default new Vuex.Store({
  state: { ...initialState },

  mutations: {
    resetState(state) {
      state.bedrooms = initialState.bedrooms;
      state.tourTimes = initialState.tourTimes;
      state.formData = initialState.formData;
    },

    setSettings(state, settings) {
      state.settings = settings;
    },

    setDesiredBedroom(state, bedroom) {
      state.formData.desiredBedroom = bedroom;
    },

    setTourDate(state, tourDate) {
      state.formData.tourDate = tourDate;
    },

    setTourTime(state, tourTime) {
      state.formData.tourTime = tourTime;
    },

    setBedroomsLoading(state) {
      state.bedrooms.loading = true;
    },

    setBedrooms(state, bedrooms) {
      state.bedrooms.loading = false;
      state.bedrooms.data = bedrooms;
    },

    setTourTimesLoading(state) {
      state.tourTimes.loading = true;
    },

    setTourTimes(state, tourTimes) {
      state.tourTimes.loading = false;
      state.tourTimes.data = tourTimes;
    },

    setAvailabilityLoading(state) {
      state.availability.loading = true;
    },

    setAvailability(state, availability) {
      state.availability.loading = false;
      state.availability.data = availability;
    },

    setAvailabilityBedroomCount(state, bedroomCount) {
      state.formData.availabilityBedroomCount = bedroomCount;
    },
  },

  actions: {
    fetchBedrooms(context, formHandler) {
      context.commit("setBedroomsLoading");
      formHandler
        .getBasicAvailableRoomData()
        .then((r) => {
          const bedrooms = r.data.bedrooms.map((bedroom) => {
            let formValue = 0;
            if (bedroom.label === "Studio") formValue = 0;
            else if (bedroom.label === "One Bedroom") formValue = 1;
            else if (bedroom.label === "Two Bedroom") formValue = 2;
            return {
              ...bedroom,
              formValue,
            };
          });
          context.commit("setBedrooms", bedrooms);
        })
        .catch(() => {
          // TODO: Generate default data
          context.commit("setBedrooms", []);
        });
    },

    fetchTourTimes(context, formHandler) {
      context.commit("setTourTimesLoading");
      formHandler
        .getTourTimes()
        .then((response) => {
          context.commit("setTourTimes", response.data);
        })
        .catch(() => {
          // TODO: Generate default data
          context.commit("setTourTimes", []);
        });
    },

    fetchAvailability(context) {
      context.commit("setAvailabilityLoading");
      const kyzenApiId = this.state.settings?.kyzen_api_id || "";
      axios
        .get(`https://api.kyzen.io/v1/properties/${kyzenApiId}/availability`)
        .then((response) => {
          context.commit("setAvailability", response.data?.data);
        })
        .catch(() => {
          context.commit("setAvailability", null);
        });
    },
  },
});
