<template>
  <column-button :title="title" :subtitle="subtitle" :selected="selected" />
</template>

<script>
import ColumnButton from "./ColumnButton.vue";
import { getDateStringFromDate, getDayNameFromDate } from "../utils";

export default {
  name: "DateButton",
  components: { ColumnButton },

  props: {
    date: {
      type: Date,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title() {
      const currentTime = new Date();
      const nextDay = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);
      if (
        currentTime.getDate() === this.date.getDate() &&
        currentTime.getMonth() === this.date.getMonth() &&
        currentTime.getFullYear() === this.date.getFullYear()
      ) {
        return "Today";
      } else if (
        nextDay.getDate() === this.date.getDate() &&
        nextDay.getMonth() === this.date.getMonth() &&
        nextDay.getFullYear() === this.date.getFullYear()
      ) {
        return getDayNameFromDate(this.date);
      }
      return getDayNameFromDate(this.date);
    },

    subtitle() {
      return getDateStringFromDate(this.date);
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 59px;
  border-radius: 50px;
  border: 3px solid var(--primaryColor);
  background: transparent;
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  max-width: 48%;

  &:hover {
    background-color: var(--primaryColorOp20);
  }

  &:focus-visible {
    outline: none;
  }

  .is-selected {
    position: absolute;
    left: 15px;
    top: 10px;
  }

  .title {
    font-size: 19px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--primaryColor);
  }

  .subtitle {
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--primaryColorOp65);
  }
}
</style>
