<template>
  <footer>
    <a href="https://dyverse.com/" target="_blank">
      <p>
        {{ footerText }} 
        <PoweredbyIcon />
      </p>
    </a>
  </footer>
</template>

<script>
import PoweredbyIcon from "../icons/PoweredbyIcon.vue";
import router from "@/router/index.js";

export default {
  name: "PageFooter",
  components: {
    PoweredbyIcon
  },
  props: {
    footerText: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleBackButton() {
      router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 1;
  text-decoration-line: none;
  color: var(--primaryColor);
  cursor: pointer;
  margin-top: auto;
  height: 46px;
  border-top: 1px solid rgba(255, 255, 255, 0.29);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
  padding-bottom: 5px;
  /*
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  */
  flex-shrink: 0;
  background-color: var(--backgroundColor);
  position: relative;
  p{
    transition: 0.3s ease-in-out;
    color: var(--primaryColor) !important;
    opacity: 0.5;
     &:hover {
      opacity: 1 !important;
   }
  }
  a:hover {
    opacity: 1 !important;
    transition: 0.3s ease-in-out;
  }
}
</style>
