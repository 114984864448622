<template>
  <header class="header">
    <goBack/>
    <goForward/>

    <div :class="{ 'animate__animated animate__fadeInUp': $route.name == 'Home' }" style="display: flex; flex-direction: column; align-items: center">
      <div class="bot-icon" v-if="logo && !customHeaderIcon">
        <img :src="headerIcon" alt="Header Icon" />
      </div>
      <img v-if="logo && customHeaderIcon" :src="headerIcon" class="bot-logo" alt="Header Icon" />
      <h2 class="animate__animated animate__fadeInUp title">{{ title }}</h2>
    </div>
  </header>
</template>

<script>
import goBack from "@/components/icons/GoBack.vue"
import goForward from "@/components/icons/GoForward.vue"
export default {
  components:{
    goBack,goForward
  },
  name: "PageHeader",
  props: {
    title: String,
    logo: {
      type: String,
      required: true,
    }
  },
  computed: {
    customHeaderIcon() {
      return this.logo.includes('https')
    },
    headerIcon() {
      if (this.logo.includes('https')) {
        return this.logo
      } else {
        return require('@/assets' + this.logo)
      }
    }
  }
 };
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  min-height: 93px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  flex-shrink: 0;
}

.bot-icon {
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* background-color: var(--primary_color); */
  background: linear-gradient(
    45deg,
    var(--iconColor) 14.64%,
    var(--iconColorLighten10) 85.36%
  );
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  color: var(--top_bar_text_color);
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    height: 32px;
    width: auto;
  }
}

.bot-logo {
  border: none;
  width: auto;
  height: 65px;
  margin-top: 3px;
  margin-bottom: 17px;
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--primaryColor);
  max-width: 350px;
  padding: 0 10px;
}
</style>
