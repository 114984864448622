<template>
  <layout :title="pageTitle" :logo="logo" class="wy-apply">
    <p class="apply-page-info">
    </p>

    <apply-form />

    <template v-slot:footer>
      <page-footer footerText="Powered by" @click.native="handleFooterClick()" />
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/layout/Layout.vue";
import PageFooter from "@/components/layout/PageFooter.vue";
import router from "../router/index";
import ApplyForm from "@/components/ApplyForm.vue";

export default {
  name: "ApplyNow",
  components: {
    Layout,
    PageFooter,
    ApplyForm,
  },
  methods: {
    handleFooterClick() {
      router.push("/");
    },
  },
  computed: {
    pageTitle() {
      let title = "Please provide some information before being directed to the application process."
      // if (this.settings.property_name) {
      //   title += " in " + this.settings.property_name;
      // }
      return title;
    },
    logo() {
      return this.$store.state.settings.interface?.logo ? this.$store.state.settings.interface.logo : '/images/bot-icon.svg';
    },
  },
  inject: ["settings"],
};
</script>

<style lang="scss" scoped>
.apply-page-info {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--primaryColorOp65);
  margin-bottom: 15px;
}
</style>
